// Variabili globali //
let scrollPosition = window.scrollY;
const body = document.querySelector('body');
const header = document.querySelector('.header');
const headerH = header.clientHeight;

// Detect touch
// import './custom/modernizr';

// Custom import //
// import './custom/autohide'; // Autohide

// Bootstrap //
import { Collapse, Modal, Offcanvas } from 'bootstrap';

// Smooth Scroll //
import SmoothScroll from "smooth-scroll";

let smoothScroll = new SmoothScroll('[href^="#"]', {
    updateURL: false,
    popstate: true,
    offset: 100,
    speed: 1000,
    speedAsDuration: true,
});

// Anime.js //
import { gsap } from "gsap";

// Create a GSAP timeline
const logoAnimation = gsap.timeline({
    defaults: { ease: "power1.out" },
    onComplete: repeatSatelliteAnimation
});

// Define the animation sequence
logoAnimation
    .from("#half_r", { duration: 1.5, opacity: 0, x: 100 })
    .from("#half_l", { duration: 1.5, opacity: 0, x: -100 }, "-=1.5")

    // Step 2: Fade out half circles
    .to("#half_r, #half_l", { duration: .5, opacity: 0 })

    // Step 3: Logo fades in
    .from("#logo", { duration: .5, opacity: 0 })

    // Step 4: Satellites fade in and orbit
    .from("#part_l", { opacity: 0, duration: 2, rotation: 360, transformOrigin: "center center" })
    .from("#part_r", { opacity: 0, duration: 2, rotation: -360, transformOrigin: "center center" }, "-=2");

// Repeat the animation of satellites orbiting
function repeatSatelliteAnimation() {
    setInterval(() => {
        gsap.from("#part_l", { duration: 2, rotation: 360, transformOrigin: "center center", immediateRender: false });
        gsap.from("#part_r", { duration: 2, rotation: -360, transformOrigin: "center center", immediateRender: false });
    }, 10000); // Repeat every 10 seconds
}

// Plyr //
import Plyr from 'plyr';

// Video Player //
const vid_player = Plyr.setup('.video-player', {
    controls: [
        'play-large',
        'play',
        'progress',
        'current-time',
        'mute',
        'volume',
        'fullscreen'
    ],
    youtube: {
        noCookie: true,
        rel: 0,
        showinfo: 0,
        modestbranding: 1
    },
    vimeo: {
        byline: false,
        portrait: false,
        title: false,
        speed: true,
        transparent: false
    }
});

// Swiper //
import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
Swiper.use([Navigation, Pagination, Autoplay]);

// Card Sliders //
const cardsSliders = document.querySelectorAll('.cards-slider');

// Iterate '.cards-slider'
cardsSliders.forEach((slider) => {
    const slides = slider.dataset.slides;

    // Initialize Swiper
    const cardsSlider = new Swiper(slider, {
        spaceBetween: 24,
        loop: true,
        watchOverflow: true,
        centerInsufficientSlides: true,
        // pagination: {
        //     el: '.cards-pagination',
        //     type: 'bullets',
        //     clickable: true,
        // },
        navigation: {
            nextEl: '.cards-next',
            prevEl: '.cards-prev',
        },
        slidesPerGroup: 1,
        breakpoints: {
            0: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: slides
            }
        }
    });
});

// Reviews Slider
const reviewSlider = new Swiper('.review-slider', {
    // spaceBetween: 24,
    loop: true,
    watchOverflow: true,
    centerInsufficientSlides: true,
    // slidesPerView: 1
    pagination: {
        el: '.review-pagination',
        type: 'bullets',
        clickable: true,
    },
    autoHeight: true
});

// Timeline Sliders //
const timelineSliders = document.querySelectorAll('.timeline-slider');

// Iterate '.timeline-slider'
timelineSliders.forEach((slider) => {
    const slides = slider.dataset.slides;

    // Initialize Swiper
    const timelineSlider = new Swiper(slider, {
        spaceBetween: 0,
        loop: false,
        watchOverflow: true,
        centerInsufficientSlides: true,
        pagination: {
            el: '.timeline-pagination',
            type: 'bullets',
            clickable: true,
        },
        breakpoints: {
            0: {
                slidesPerView: 1,
                slidesPerGroup: 1
            },
            768: {
                slidesPerView: 2,
                slidesPerGroup: 2
            },
            1200: {
                slidesPerView: slides,
                slidesPerGroup: 2
            }
        }
    });
});

// Gallery Sliders //
const gallerySlider = document.querySelectorAll('.gallery-slider');

// Iterate '.gallery-slider'
gallerySlider.forEach((slider) => {
    const slides = parseInt(slider.dataset.slides, 10);

    // Opzioni base
    let swiperOptions = {
        spaceBetween: 24,
        loop: false,
        watchOverflow: true,
        centerInsufficientSlides: true,
        // pagination: {
        //     el: '.gallery-pagination',
        //     type: 'bullets',
        //     clickable: true,
        // },
        navigation: {
            nextEl: '.gallery-next',
            prevEl: '.gallery-prev',
        },
        slidesPerView: 1,
        slidesPerGroup: 1,
    };

    // Se slide multiple
    if (slides !== 1) {
        swiperOptions.breakpoints = {
            768: {
                slidesPerView: 2,
                slidesPerGroup: 2,
            },
            992: {
                slidesPerView: 3,
                slidesPerGroup: 3,
            },
            1200: {
                slidesPerView: slides,
                slidesPerGroup: 3,
            }
        };
    } else if (slides === 2) {
        swiperOptions.breakpoints = {
            768: {
                slidesPerView: 2,
                slidesPerGroup: 2,
            },
            992: {
                slidesPerView: 2,
                slidesPerGroup: 2,
            },
            1200: {
                slidesPerView: slides,
                slidesPerGroup: 3,
            }
        };

    }

    // Avvia slider
    const gallerySlider = new Swiper(slider, swiperOptions);
});

// HOVER INTENT //
import './custom/hoverintent';

// Elementi menu
let menuItems = document.querySelectorAll('.nav-item');

// Riconoscimento device touch
function isTouchDevice() {
    return true == ("ontouchstart" in window || window.DocumentTouch && document instanceof DocumentTouch);
}

if (isTouchDevice() === true) {
    menuItems.forEach(function (it) {
        it.classList.add('touch-device');
    });
    // console.log('Touch Device');
} else {
    // imposta azioni enter ed exit
    let hi = new BZN.HoverIntent(menuItems, {
        // parametri
        onEnter: function (targetItem) {
            targetItem.classList.add('visible');
        },
        onExit: function (targetItem) {
            targetItem.classList.remove('visible');
        },

        // opzioni
        exitDelay: 400,
        interval: 100,
        sensitivity: 7,
    });
    // console.log('Not a Touch Device');
}

// Scrollspy
import VanillaScrollspy from 'vanillajs-scrollspy'

const menu = document.querySelector('#glossary-index')

if (menu) {  // Check if the element exists
    const scrollspy = VanillaScrollspy({ menu })
    scrollspy.init()
} else {
    // console.log('#glossary-index not found. Scrollspy not initialized.');
}

// CARDS
const cards = document.querySelectorAll('.card.with-back');

// Funzione al click di ogni card
cards.forEach(card => {
    const trigger = card.querySelector('.trigger');
    if (trigger) {
        trigger.addEventListener('click', () => {
            // Assegna classe 'active'
            card.classList.toggle('active');

            // Reset delle altre card attive
            cards.forEach(otherCard => {
                if (otherCard !== card && otherCard.classList.contains('active')) {
                    otherCard.classList.remove('active');
                }
            });
        });
    }
});

// Decompress ASCII //
function decompressASCII(art) {
    const decompressed = art.replace(/(\d+)/g, (match, number) => ' '.repeat(number));
    console.log(decompressed.replace(/\\n\\r/g, '\n').replace(/\\n/g, '\n').replace(/\\r/g, ''));
}

// Credits //
document.addEventListener("DOMContentLoaded", function () {
    const art = "****97\n\r****22****71\n\r****22****71\n\r****97\n\r****97\n\r****4******12****6****************9*********15**********6\n\r******************8****5*****************6***************9****************3\n\r********6*****7****16*****5*****9*****5*****10***** \n\r*****11*****5****14*****6*****11*****3****13****1\n\r****13****5****13*****7*********************3****14****\n\r****13****5****11*****9****20****14****\n\r****13****5****10****11****20****14****\n\r1*****9*****6****8*****13****11****4****14****\n\r3****************7****6***************6******6*****5****14****\n\r6**********10****5*****************7*************7****14****\n\r";
    console.log('A DIGITAL PROJECT BY');
    decompressASCII(art);
});